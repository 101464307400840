import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, HeadingOne } from '../../atoms';
import { EditPostIcon } from '../../svgs/EditPost';
import { DeletePostIcon } from '../../svgs/DeletePost';
import {
  subscribeToNews,
  unsubscribeFromNews,
  useAppDispatch,
  useAppSelector
} from '../../state';
import { openModal } from '../../state/actions/modalsActions';
import { CommunityState } from '../../api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { texts } from '../../text';
import { selectCommunityData } from '../../state/selectors/communitySelector';
import { useDeleteCommunity } from './hooks/useDeleteCommunity';
import { selectIsUserAdmin } from '../../state/selectors/userSelector';
import { ConfirmationModalWrapper } from '../../components/modals';
import { Tooltip } from '../../components/core/tooltip';
import { useAuth } from '../../state/hooks/useAuth';
import { Subscribe } from '../../svgs/Subscribe';
import { Unsubscribe } from '../../svgs/Unsubscribe';
import { clearSubscriptions } from '../../state/slices/userReducer';
import { useNotifications } from '../../components/notificationProvider/hooks/useNotifications';
import { createLink, getErrorMessage } from '../../utils/helperFunctions';

interface CommunityTitlePlaceProps {}

export const CommunityTitlePlace: FC<CommunityTitlePlaceProps> = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useParams();
  const dispatch = useAppDispatch();
  const communityData = useAppSelector(selectCommunityData);
  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const { user } = useAuth();
  const { notify } = useNotifications();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<
    'subscribe' | 'unsubscribe' | null
  >(null);

  const isUserCreator = useMemo(() => {
    return user && communityData?.createdByUser?.id
      ? Number(user.id) === Number(communityData.createdByUser.id)
      : false;
  }, [user, communityData?.createdByUser?.id]);

  const handleOpenModal = useCallback(
    (modalName: string) => {
      dispatch(openModal(modalName));
    },
    [dispatch]
  );

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [location.search, navigate]
  );

  const openLoginModal = useCallback(
    () => handleOpenModalHash('login'),
    [handleOpenModalHash]
  );

  // -----------------------------
  // DELETE ACTION (uses confirmation modal)
  // -----------------------------
  const handleDeleteSuccess = useCallback(() => {
    setIsLoading(false);
    setIsConfirmationModalOpen(false);
    if (communityData && communityData.urlKey) {
      notify(
        'success',
        `The ${communityData.name} community successfully deleted.`
      );
    }
    navigate(createLink('/papers'));
  }, [communityData, notify, navigate]);

  const handleDeleteError = useCallback(
    (error: any) => {
      setIsLoading(false);
      if (!user) {
        dispatch(openModal('login'));
      } else if (communityData && communityData.urlKey) {
        notify('error', `Action failed:  ${getErrorMessage(error)}`);
      }
    },
    [communityData, user, dispatch, notify]
  );

  const deleteCommunity = useDeleteCommunity(
    handleDeleteSuccess,
    handleDeleteError
  );

  const handleConfirmDelete = useCallback(() => {
    if (communityData?.urlKey) {
      setIsConfirmationModalOpen(true);
    }
  }, [communityData]);

  // -----------------------------
  // SUBSCRIBE ACTION (immediate if no URL param; confirmation modal if URL indicates action)
  // -----------------------------
  const handleSubscribeAction = useCallback(async () => {
    if (!communityData?.urlKey) return;
    setIsLoading(true);
    try {
      await dispatch(
        subscribeToNews({ urlKey: communityData.urlKey })
      ).unwrap();
      dispatch(clearSubscriptions());
      notify('success', `You are now subscribed to ${communityData.name}.`);
    } catch (error) {
      notify('error', `Subscribe failed:  ${getErrorMessage(error)}`);
    } finally {
      setIsLoading(false);
      setPendingAction(null);
      setIsConfirmationModalOpen(false);
    }
  }, [communityData, dispatch, notify]);

  // -----------------------------
  // UNSUBSCRIBE ACTION (immediate if no URL param; confirmation modal if URL indicates action)
  // -----------------------------
  const handleUnsubscribeAction = useCallback(async () => {
    if (!communityData?.urlKey) return;
    setIsLoading(true);
    try {
      await dispatch(
        unsubscribeFromNews({ urlKey: communityData.urlKey })
      ).unwrap();
      dispatch(clearSubscriptions());
      notify(
        'success',
        `You are no longer subscribed to ${communityData.name}.`
      );
    } catch (error) {
      notify('error', `Unsubscribe failed:  ${getErrorMessage(error)}`);
    } finally {
      setIsLoading(false);
      setPendingAction(null);
      setIsConfirmationModalOpen(false);
    }
  }, [communityData, dispatch, notify]);

  // -----------------------------
  // HANDLERS FOR BUTTON CLICKS
  // -----------------------------
  const handleSubscribeToNews = useCallback(async () => {
    setPendingAction('subscribe');
    if (action === 'subscribe') {
      setIsConfirmationModalOpen(true);
    } else {
      await handleSubscribeAction();
    }
  }, [handleSubscribeAction, action]);

  const handleUnsubscribeToNews = useCallback(async () => {
    setPendingAction('unsubscribe');
    if (action === 'unsubscribe') {
      setIsConfirmationModalOpen(true);
    } else {
      await handleUnsubscribeAction();
    }
  }, [handleUnsubscribeAction, action]);

  const handleCloseModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
    setPendingAction(null);
  }, []);

  // -----------------------------
  // URL Effect: If the URL includes an action, open the confirmation modal.
  // On default pages (no URL action), nothing happens.
  useEffect(() => {
    if (action === 'subscribe' || action === 'unsubscribe') {
      setPendingAction(action);
      setIsConfirmationModalOpen(true);
    }
  }, [action]);

  return (
    <main className="page-content pt-10 pb-8 px-4">
      <div className="max-w-3xl m-auto">
        <Flex direction="flex-row w-full">
          <Flex className="w-full">
            <div className="flex flex-row items-start justify-between mb-2">
              <div className="">
                <HeadingOne color="black" className="mb-0">
                  {communityData?.name}
                </HeadingOne>
              </div>
              {communityData?.state === CommunityState.Active && (
                <div className="flex flex-row">
                  {user && (isUserAdmin || isUserCreator) && (
                    <div
                      onClick={() => handleOpenModal('updateCommunity')}
                      className="mr-1 cursor-pointer"
                    >
                      <Tooltip className="tooltipSm" text={'Edit'}>
                        <EditPostIcon />
                      </Tooltip>
                    </div>
                  )}
                  {user && (isUserAdmin || isUserCreator) && (
                    <div
                      className="cursor-pointer"
                      onClick={handleConfirmDelete}
                    >
                      <Tooltip className="tooltipSm" text={'Delete'}>
                        <DeletePostIcon className="cursor-pointer" />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col tablet:flex-row laptop:flex-row desktop:flex-row justify-between items-center">
              <div className="text-primary-500 text-sm mr-1">
                #C[{communityData?.name}]
              </div>
              {!user ? (
                <div
                  className="cursor-pointer flex items-center"
                  onClick={openLoginModal}
                >
                  <Tooltip
                    text="You must be logged in to subscribe to the latest community news."
                    className="tooltipSubs"
                  >
                    <span className="text-gray-400 flex items-center">
                      Subscribe <Subscribe />
                    </span>
                  </Tooltip>
                </div>
              ) : !communityData?.isSubscribedToNews ? (
                <div
                  onClick={isLoading ? undefined : handleSubscribeToNews}
                  className="cursor-pointer flex items-center"
                >
                  <Tooltip
                    text="Stay informed with the latest research, discussions, and updates from this community!"
                    className="tooltipSubs"
                  >
                    Subscribe <Subscribe />
                  </Tooltip>
                </div>
              ) : (
                <div
                  onClick={isLoading ? undefined : handleUnsubscribeToNews}
                  className="cursor-pointer flex items-center"
                >
                  <Tooltip
                    text="Unsubscribe if you no longer wish to receive updates from this community. You’re welcome back anytime!"
                    className="tooltipSubs"
                  >
                    Unsubscribe <Unsubscribe />
                  </Tooltip>
                </div>
              )}
            </div>

            {communityData?.state !== CommunityState.Active && (
              <div className="text-sm mt-10">{texts.disabledCommunity}</div>
            )}
          </Flex>
        </Flex>
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModalWrapper
          isOpen={isConfirmationModalOpen}
          title={
            pendingAction === 'subscribe'
              ? 'Subscribe to Updates'
              : pendingAction === 'unsubscribe'
                ? 'Unsubscribe from Updates'
                : 'Confirm Deletion'
          }
          message={
            pendingAction === 'subscribe'
              ? // eslint-disable-next-line quotes
                "You're about to subscribe to community news and updates. Stay informed with the latest announcements and events!"
              : pendingAction === 'unsubscribe'
                ? 'Are you sure you want to unsubscribe from community news and updates? You may miss important announcements and events.'
                : 'Are you sure you want to delete this community?'
          }
          onConfirm={
            pendingAction === 'subscribe'
              ? handleSubscribeAction
              : pendingAction === 'unsubscribe'
                ? handleUnsubscribeAction
                : () => {
                    if (communityData?.urlKey) {
                      deleteCommunity({ urlKey: communityData.urlKey });
                    }
                  }
          }
          onClose={handleCloseModal}
          loading={isLoading}
        />
      )}
    </main>
  );
};
