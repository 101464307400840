import React, { FC, useState } from 'react';
import { AccordionItem } from '../../components/accordion';
import { IPaperLink } from '../../types/paper.type';
import { ArrowDown } from '../../svgs';

interface PaperAccrodionLinksProps {
  links?: IPaperLink[];
  handleExternalLink: React.MouseEventHandler<HTMLAnchorElement>;
}

export const PaperAccrodionLinks: FC<PaperAccrodionLinksProps> = ({
  links,
  handleExternalLink
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleToggle = () => {
    if (links && links.length > 0) {
      setIsExpanded((prev) => !prev);
    }
  };

  const disabled = !links || links.length === 0;

  return (
    <AccordionItem
      title="Alternative Links"
      isExpanded={isExpanded}
      className="papersMetaTooltip"
      onToggle={handleToggle}
      disabled={disabled}
      tooltipText={disabled ? 'No alternative links available' : undefined}
      collapsedIcon={<ArrowDown className="w-4 h-4 transform rotate-0" />}
      expandedIcon={<ArrowDown className="w-4 h-4 transform rotate-180" />}
    >
      <div className="tablet:ml-auto block tablet:mb-0">
        {disabled ? (
          <div className="text-gray-500">No alternative links available.</div>
        ) : (
          links.map((l) => {
            const url = l.url;
            return (
              <a
                key={l.url}
                className="text-primary-500 hover:text-primary-400 active:text-primary-200 text-small font-medium transition-colors duration-200 mt-2 flex"
                href={url!}
                onClick={handleExternalLink}
                target="_blank"
                rel="noreferrer"
              >
                {url}
              </a>
            );
          })
        )}
      </div>
    </AccordionItem>
  );
};
