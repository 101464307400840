import classNames from 'classnames';
import React, { FC, useState, useRef, useEffect } from 'react';
import { Tooltip } from '../core/tooltip';
import { QuestionMark } from '../../svgs';

export type AccordionItemProps = {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  tooltipText?: string;
  className?: string;
  expandedIcon?: React.ReactNode;
  collapsedIcon?: React.ReactNode;
};

export const AccordionItem: FC<AccordionItemProps> = ({
  title,
  isExpanded,
  onToggle,
  children,
  disabled = false,
  tooltipText,
  className,
  expandedIcon,
  collapsedIcon
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const marginClass =
    expandedIcon !== undefined && collapsedIcon !== undefined
      ? 'mt-1'
      : isExpanded
        ? 'mt-2'
        : '';

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div
      className={classNames(
        'accordion-item box-border px-6 pt-8 pb-8 mb-4',
        className
      )}
    >
      <button
        onClick={disabled ? undefined : onToggle}
        disabled={disabled}
        type="button"
        className={classNames(
          'accordion-title-wrap flex justify-between items-center',
          {
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled
          }
        )}
      >
        <span className="accordion-title flex items-center">
          <span className={classNames({ 'opacity-50': disabled })}>
            {title}
          </span>
          {disabled && tooltipText && (
            <Tooltip
              className="tooltipComments tooltipCitations ml-5"
              text={tooltipText}
            >
              <QuestionMark />
            </Tooltip>
          )}
        </span>
        <span
          className={classNames('accordion-toggle', {
            'accordion-toggle-active': isExpanded,
            'opacity-50': disabled
          })}
        >
          {isExpanded
            ? expandedIcon !== undefined
              ? expandedIcon
              : '-'
            : collapsedIcon !== undefined
              ? collapsedIcon
              : '+'}
        </span>
      </button>
      <div
        ref={contentRef}
        className={`accordion-content text-sm ${marginClass}`}
        style={{
          height: `${height}px`,
          transition: 'all 0.3s ease',
          backgroundColor: 'transparent'
        }}
      >
        {children}
      </div>
    </div>
  );
};
