/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { NotFound } from './not-found';
import { Homepage } from './homepage';
import { ErrorPage } from './error';
import { PaperPage } from './paper';
import { CommunityContainer } from './community';
import { Feed } from './feed';
import { Search } from './search';
import { UserPostPage } from './post';
import { ReferralPageContainer, InvitePage } from './referral';
import { AboutPage, ContactPage, TermsAndConditionsPage } from './static-pages';
import { TopicContainer } from './topic-v2';
import { Root } from './root';
import { ResetPasswordPageContainer } from './reset-password/ResetPasswordPageContainer';
import { createLink } from '../utils/helperFunctions';
import LandingPage from '../landing/landingPage';
import { MyProfileContainer } from './myProfile/myProfileContainer';

interface Props {}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LandingPage />
      },
      {
        path: createLink(''),
        element: <Homepage />
      },
      {
        path: createLink('about'),
        element: <AboutPage />
      },
      {
        path: createLink('topics'),
        element: <Homepage />,
        children: []
      },
      {
        path: createLink('communities'),
        element: <Homepage />,
        children: []
      },
      {
        path: createLink('papers'),
        element: <Homepage />,
        children: []
      },
      {
        path: createLink('discussions'),
        element: <Homepage />,
        children: []
      },
      {
        path: createLink('paper'),
        children: [
          {
            path: createLink('paper/:id'),
            element: <PaperPage />
          }
        ]
      },
      {
        path: createLink('community'),
        children: [
          {
            path: createLink('community/:urlKey'),
            element: <CommunityContainer />
          },
          {
            path: createLink('community/:urlKey/papers'),
            element: <CommunityContainer /> //PapersCommunityList
          },
          {
            path: createLink('community/:urlKey/discussions'),
            element: <CommunityContainer /> // PapersTopicList
          },
          {
            path: createLink('community/:urlKey/subscriptions/news/:action'),
            element: <CommunityContainer />
          }
        ]
      },
      {
        path: createLink('topic'),
        children: [
          {
            path: createLink('topic/:urlKey'),
            element: <TopicContainer />
          },
          {
            path: createLink('topic/:urlKey/papers'),
            element: <TopicContainer />
          },
          {
            path: createLink('topic/:urlKey/discussions'),
            element: <TopicContainer />
          }
        ]
      },
      {
        path: createLink('posts/:id'),
        element: <UserPostPage />
      },
      {
        path: createLink('referral'),
        element: <ReferralPageContainer />
      },
      {
        path: createLink('invite'),
        element: <InvitePage />
      },
      {
        path: createLink(':type/search'),
        element: <Search />
      },
      {
        path: createLink('feed'),
        element: <Feed />
      },
      {
        path: createLink('contact'),
        element: <ContactPage />
      },
      {
        path: createLink('terms-and-conditions'),
        element: <TermsAndConditionsPage />
      },
      {
        path: createLink('profiles/my/:type'),
        element: <MyProfileContainer />
      },
      {
        path: createLink('reset-password'),
        element: <ResetPasswordPageContainer />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]);

export const AppRoutes: FC<Props> = () => {
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
};
