import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Field, Input, Label, Validation } from '../../components/form';
import { useNavigate, useLocation } from 'react-router-dom';
import { passwordValidationSchema } from '../register/register';
import { QuestionMark, VisibilityHidden, VisibilityShow } from '../../svgs';
import { Tooltip } from '../../components/core/tooltip';
import { createLink } from '../../utils/helperFunctions';
import { IResetPasswordRequest } from '../../types/auth.type';
import { useAuth } from '../../state/hooks/useAuth';
import { PasswordRequirements } from './passwordRequirements';
import { useNotifications } from '../../components/notificationProvider/hooks/useNotifications';

const initialValues = {
  password: '',
  confirmPassword: '',
  showPassword: false
};

type FormData = typeof initialValues;

const validationSchema = Yup.object().shape({
  password: passwordValidationSchema,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .when('showPassword', {
      is: false,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired()
    })
});

export const ResetPasswordPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { resetPassword } = useAuth();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token') || '';
  const email = queryParams.get('email') || '';
  const { notify } = useNotifications();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    const newShowPassword = !showPassword;
    setShowPassword(newShowPassword);
    formik.setFieldValue('showPassword', newShowPassword);
  };

  const handlePasswordBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(e);
  };

  useEffect(() => {
    if (!token || !email) {
      navigate(createLink('papers/#login'), { replace: true });
    }
  }, [token, email, navigate]);

  async function handleSubmit(values: FormData) {
    try {
      const resetData: IResetPasswordRequest = {
        token,
        email,
        newPassword: values.password
      };
      resetPassword(resetData);
      notify(
        'success',
        'Your password has been successfully changed. You will be redirected automatically to the home page.',
        {
          toastId: `resetpass-${email}`
        }
      );
      navigate(createLink('/papers'));
    } catch (err: any) {
      let errorMessage =
        'There was a problem resetting your password. Please try again.';
      if (err instanceof Response) {
        const errorData = await err.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorMessage = errorData.errors.join(' ');
          errorMessage += ' Please try again.';
        }
      }
      notify('error', `${errorMessage}`, {
        toastId: `resetpass-error-${email}`
      });
    }
  }

  const formik = useFormik<FormData>({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema
  });

  return (
    <div>
      <form
        className="w-full max-w-md mx-auto mt-16 register-form"
        onSubmit={formik.handleSubmit}
      >
        <Field className="">
          <div className="flex justify-between">
            <Label htmlFor="password">Password</Label>
            <Tooltip
              className="tooltipPassword tooltipResetPassword"
              text={<PasswordRequirements />}
            >
              <QuestionMark />
            </Tooltip>
          </div>
          <div className="relative">
            <Input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={formik.handleChange}
              onBlur={handlePasswordBlur}
              value={formik.values.password}
              className={`pr-5 ${
                formik.touched.password && formik.errors.password
                  ? 'border-error-500'
                  : ''
              } ${formik.touched.password && !formik.errors.password ? 'border-green-500' : ''}`}
              placeholder="********"
            />
            <button
              type="button"
              className="absolute right-0 showHideBtn"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityShow /> : <VisibilityHidden />}
            </button>
          </div>
          {formik.errors.password && formik.touched.password && (
            <Validation message={formik.errors.password} />
          )}
        </Field>
        {!showPassword && (
          <Field className="">
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              className={`${
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? 'border-error-500'
                  : ''
              } ${formik.touched.confirmPassword && !formik.errors.confirmPassword ? 'border-green-500' : ''}`}
              placeholder="********"
            />
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <Validation message={formik.errors.confirmPassword} />
              )}
          </Field>
        )}
        <div className="flex justify-center">
          <Button type="submit" className="px-8 py-2">
            Reset Password
          </Button>
        </div>
      </form>
    </div>
  );
};
